/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { CourseModule } from '../models';
// @ts-ignore
import type { DigitalTextbookSectionListingWithProblems } from '../models';
// @ts-ignore
import type { FrontofficePage } from '../models';
// @ts-ignore
import type { HighSchoolList } from '../models';
// @ts-ignore
import type { LastViewedCourseModule } from '../models';
// @ts-ignore
import type { Lesson } from '../models';
// @ts-ignore
import type { PaginatedCourseModuleList } from '../models';
// @ts-ignore
import type { PaginatedDigitalTextbookSectionListingWithProblemsList } from '../models';
// @ts-ignore
import type { PaginatedFrontofficePageList } from '../models';
// @ts-ignore
import type { PaginatedLessonListList } from '../models';
// @ts-ignore
import type { PaginatedSelfRetrieveUserList } from '../models';
// @ts-ignore
import type { PaginatedViewedLessonListList } from '../models';
// @ts-ignore
import type { PaginatedViewedProblemListList } from '../models';
// @ts-ignore
import type { PatchedSelfUpdateUserRequest } from '../models';
// @ts-ignore
import type { PatchedViewedLessonRequest } from '../models';
// @ts-ignore
import type { PatchedViewedProblemRequest } from '../models';
// @ts-ignore
import type { SelfRetrieveUser } from '../models';
// @ts-ignore
import type { SelfRetrieveUserRequest } from '../models';
// @ts-ignore
import type { SelfUpdateUser } from '../models';
// @ts-ignore
import type { UniversityList } from '../models';
// @ts-ignore
import type { UniversityProgramsList } from '../models';
// @ts-ignore
import type { ViewedLesson } from '../models';
// @ts-ignore
import type { ViewedLessonCreate } from '../models';
// @ts-ignore
import type { ViewedLessonCreateRequest } from '../models';
// @ts-ignore
import type { ViewedLessonRequest } from '../models';
// @ts-ignore
import type { ViewedProblem } from '../models';
// @ts-ignore
import type { ViewedProblemCreate } from '../models';
// @ts-ignore
import type { ViewedProblemCreateRequest } from '../models';
// @ts-ignore
import type { ViewedProblemRequest } from '../models';
/**
 * FrontofficeApi - axios parameter creator
 * @export
 */
export const FrontofficeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ViewedLessonCreateRequest} viewedLessonCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createViewedLesson: async (viewedLessonCreateRequest: ViewedLessonCreateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'viewedLessonCreateRequest' is not null or undefined
            assertParamExists('createViewedLesson', 'viewedLessonCreateRequest', viewedLessonCreateRequest)
            const localVarPath = `/wt/frontoffice/api/viewed-lesson/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(viewedLessonCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ViewedProblemCreateRequest} viewedProblemCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createViewedProblem: async (viewedProblemCreateRequest: ViewedProblemCreateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'viewedProblemCreateRequest' is not null or undefined
            assertParamExists('createViewedProblem', 'viewedProblemCreateRequest', viewedProblemCreateRequest)
            const localVarPath = `/wt/frontoffice/api/viewed-problem/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(viewedProblemCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiCourseModuleList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/frontoffice/api/course-module/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiCourseModulePreviewRetrieve: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/frontoffice/api/course-module/preview/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SelfRetrieveUserRequest} selfRetrieveUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiCurrentUserCreate: async (selfRetrieveUserRequest: SelfRetrieveUserRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'selfRetrieveUserRequest' is not null or undefined
            assertParamExists('frontofficeApiCurrentUserCreate', 'selfRetrieveUserRequest', selfRetrieveUserRequest)
            const localVarPath = `/wt/frontoffice/api/current-user/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(selfRetrieveUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiCurrentUserDestroy: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('frontofficeApiCurrentUserDestroy', 'id', id)
            const localVarPath = `/wt/frontoffice/api/current-user/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiCurrentUserList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/frontoffice/api/current-user/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user.
         * @param {SelfRetrieveUserRequest} selfRetrieveUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiCurrentUserUpdate: async (id: number, selfRetrieveUserRequest: SelfRetrieveUserRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('frontofficeApiCurrentUserUpdate', 'id', id)
            // verify required parameter 'selfRetrieveUserRequest' is not null or undefined
            assertParamExists('frontofficeApiCurrentUserUpdate', 'selfRetrieveUserRequest', selfRetrieveUserRequest)
            const localVarPath = `/wt/frontoffice/api/current-user/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(selfRetrieveUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiDigitalTextbookSectionListingList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/frontoffice/api/digital-textbook-section-listing/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiPageList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/frontoffice/api/page/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Viewed Lesson.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiViewedLessonDestroy: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('frontofficeApiViewedLessonDestroy', 'id', id)
            const localVarPath = `/wt/frontoffice/api/viewed-lesson/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Viewed Lesson.
         * @param {PatchedViewedLessonRequest} [patchedViewedLessonRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiViewedLessonPartialUpdate: async (id: number, patchedViewedLessonRequest?: PatchedViewedLessonRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('frontofficeApiViewedLessonPartialUpdate', 'id', id)
            const localVarPath = `/wt/frontoffice/api/viewed-lesson/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedViewedLessonRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Viewed Lesson.
         * @param {ViewedLessonRequest} viewedLessonRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiViewedLessonUpdate: async (id: number, viewedLessonRequest: ViewedLessonRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('frontofficeApiViewedLessonUpdate', 'id', id)
            // verify required parameter 'viewedLessonRequest' is not null or undefined
            assertParamExists('frontofficeApiViewedLessonUpdate', 'viewedLessonRequest', viewedLessonRequest)
            const localVarPath = `/wt/frontoffice/api/viewed-lesson/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(viewedLessonRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Viewed Problem.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiViewedProblemDestroy: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('frontofficeApiViewedProblemDestroy', 'id', id)
            const localVarPath = `/wt/frontoffice/api/viewed-problem/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Viewed Problem.
         * @param {PatchedViewedProblemRequest} [patchedViewedProblemRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiViewedProblemPartialUpdate: async (id: number, patchedViewedProblemRequest?: PatchedViewedProblemRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('frontofficeApiViewedProblemPartialUpdate', 'id', id)
            const localVarPath = `/wt/frontoffice/api/viewed-problem/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedViewedProblemRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Viewed Problem.
         * @param {ViewedProblemRequest} viewedProblemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiViewedProblemUpdate: async (id: number, viewedProblemRequest: ViewedProblemRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('frontofficeApiViewedProblemUpdate', 'id', id)
            // verify required parameter 'viewedProblemRequest' is not null or undefined
            assertParamExists('frontofficeApiViewedProblemUpdate', 'viewedProblemRequest', viewedProblemRequest)
            const localVarPath = `/wt/frontoffice/api/viewed-problem/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(viewedProblemRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this course module.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseModule: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCourseModule', 'id', id)
            const localVarPath = `/wt/frontoffice/api/course-module/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUser: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCurrentUser', 'id', id)
            const localVarPath = `/wt/frontoffice/api/current-user/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this digital textbook section listing.
         * @param {number} [digitalTextbookListing] Digital textbook listing id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDigitalTextbookSectionListing: async (id: number, digitalTextbookListing?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDigitalTextbookSectionListing', 'id', id)
            const localVarPath = `/wt/frontoffice/api/digital-textbook-section-listing/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (digitalTextbookListing !== undefined) {
                localVarQueryParameter['digital_textbook_listing'] = digitalTextbookListing;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this frontoffice page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFrontofficePage: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFrontofficePage', 'id', id)
            const localVarPath = `/wt/frontoffice/api/page/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHighSchoolList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/frontoffice/api/high-school-list/list/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastViewedCourseModule: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/frontoffice/api/viewed-lesson/last-viewed-course-module/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} course 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastViewedLessonInCourse: async (course: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'course' is not null or undefined
            assertParamExists('getLastViewedLessonInCourse', 'course', course)
            const localVarPath = `/wt/frontoffice/api/viewed-lesson/last-for-course/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (course !== undefined) {
                localVarQueryParameter['course'] = course;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} courseModule 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastViewedLessonInCourseModule: async (courseModule: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseModule' is not null or undefined
            assertParamExists('getLastViewedLessonInCourseModule', 'courseModule', courseModule)
            const localVarPath = `/wt/frontoffice/api/viewed-lesson/last-for-course-module/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (courseModule !== undefined) {
                localVarQueryParameter['course_module'] = courseModule;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Lesson.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLesson: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLesson', 'id', id)
            const localVarPath = `/wt/frontoffice/api/lesson/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLessonList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/frontoffice/api/lesson/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUniversityList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/frontoffice/api/university/list/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUniversityProgramsList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/frontoffice/api/university/programs-list/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Viewed Lesson.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViewedLesson: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getViewedLesson', 'id', id)
            const localVarPath = `/wt/frontoffice/api/viewed-lesson/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} courseModule 
         * @param {number} [course] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {number} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViewedLessonList: async (courseModule: number, course?: number, limit?: number, offset?: number, user?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseModule' is not null or undefined
            assertParamExists('getViewedLessonList', 'courseModule', courseModule)
            const localVarPath = `/wt/frontoffice/api/viewed-lesson/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (course !== undefined) {
                localVarQueryParameter['course'] = course;
            }

            if (courseModule !== undefined) {
                localVarQueryParameter['course_module'] = courseModule;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (user !== undefined) {
                localVarQueryParameter['user'] = user;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Viewed Problem.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViewedProblem: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getViewedProblem', 'id', id)
            const localVarPath = `/wt/frontoffice/api/viewed-problem/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [digitalTextbookSectionListing] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {number} [problemSource] 
         * @param {number} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViewedProblemList: async (digitalTextbookSectionListing?: number, limit?: number, offset?: number, problemSource?: number, user?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/frontoffice/api/viewed-problem/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (digitalTextbookSectionListing !== undefined) {
                localVarQueryParameter['digital_textbook_section_listing'] = digitalTextbookSectionListing;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (problemSource !== undefined) {
                localVarQueryParameter['problem_source'] = problemSource;
            }

            if (user !== undefined) {
                localVarQueryParameter['user'] = user;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user.
         * @param {PatchedSelfUpdateUserRequest} [patchedSelfUpdateUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCurrentUser: async (id: number, patchedSelfUpdateUserRequest?: PatchedSelfUpdateUserRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCurrentUser', 'id', id)
            const localVarPath = `/wt/frontoffice/api/current-user/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedSelfUpdateUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FrontofficeApi - functional programming interface
 * @export
 */
export const FrontofficeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FrontofficeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ViewedLessonCreateRequest} viewedLessonCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createViewedLesson(viewedLessonCreateRequest: ViewedLessonCreateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ViewedLessonCreate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createViewedLesson(viewedLessonCreateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.createViewedLesson']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ViewedProblemCreateRequest} viewedProblemCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createViewedProblem(viewedProblemCreateRequest: ViewedProblemCreateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ViewedProblemCreate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createViewedProblem(viewedProblemCreateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.createViewedProblem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async frontofficeApiCourseModuleList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedCourseModuleList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.frontofficeApiCourseModuleList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.frontofficeApiCourseModuleList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async frontofficeApiCourseModulePreviewRetrieve(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseModule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.frontofficeApiCourseModulePreviewRetrieve(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.frontofficeApiCourseModulePreviewRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {SelfRetrieveUserRequest} selfRetrieveUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async frontofficeApiCurrentUserCreate(selfRetrieveUserRequest: SelfRetrieveUserRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelfRetrieveUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.frontofficeApiCurrentUserCreate(selfRetrieveUserRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.frontofficeApiCurrentUserCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async frontofficeApiCurrentUserDestroy(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.frontofficeApiCurrentUserDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.frontofficeApiCurrentUserDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async frontofficeApiCurrentUserList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedSelfRetrieveUserList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.frontofficeApiCurrentUserList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.frontofficeApiCurrentUserList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user.
         * @param {SelfRetrieveUserRequest} selfRetrieveUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async frontofficeApiCurrentUserUpdate(id: number, selfRetrieveUserRequest: SelfRetrieveUserRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelfRetrieveUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.frontofficeApiCurrentUserUpdate(id, selfRetrieveUserRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.frontofficeApiCurrentUserUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async frontofficeApiDigitalTextbookSectionListingList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedDigitalTextbookSectionListingWithProblemsList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.frontofficeApiDigitalTextbookSectionListingList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.frontofficeApiDigitalTextbookSectionListingList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async frontofficeApiPageList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedFrontofficePageList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.frontofficeApiPageList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.frontofficeApiPageList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Viewed Lesson.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async frontofficeApiViewedLessonDestroy(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.frontofficeApiViewedLessonDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.frontofficeApiViewedLessonDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Viewed Lesson.
         * @param {PatchedViewedLessonRequest} [patchedViewedLessonRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async frontofficeApiViewedLessonPartialUpdate(id: number, patchedViewedLessonRequest?: PatchedViewedLessonRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ViewedLesson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.frontofficeApiViewedLessonPartialUpdate(id, patchedViewedLessonRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.frontofficeApiViewedLessonPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Viewed Lesson.
         * @param {ViewedLessonRequest} viewedLessonRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async frontofficeApiViewedLessonUpdate(id: number, viewedLessonRequest: ViewedLessonRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ViewedLesson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.frontofficeApiViewedLessonUpdate(id, viewedLessonRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.frontofficeApiViewedLessonUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Viewed Problem.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async frontofficeApiViewedProblemDestroy(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.frontofficeApiViewedProblemDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.frontofficeApiViewedProblemDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Viewed Problem.
         * @param {PatchedViewedProblemRequest} [patchedViewedProblemRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async frontofficeApiViewedProblemPartialUpdate(id: number, patchedViewedProblemRequest?: PatchedViewedProblemRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ViewedProblem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.frontofficeApiViewedProblemPartialUpdate(id, patchedViewedProblemRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.frontofficeApiViewedProblemPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Viewed Problem.
         * @param {ViewedProblemRequest} viewedProblemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async frontofficeApiViewedProblemUpdate(id: number, viewedProblemRequest: ViewedProblemRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ViewedProblem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.frontofficeApiViewedProblemUpdate(id, viewedProblemRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.frontofficeApiViewedProblemUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this course module.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourseModule(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseModule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCourseModule(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.getCourseModule']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentUser(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelfRetrieveUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentUser(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.getCurrentUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this digital textbook section listing.
         * @param {number} [digitalTextbookListing] Digital textbook listing id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDigitalTextbookSectionListing(id: number, digitalTextbookListing?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DigitalTextbookSectionListingWithProblems>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDigitalTextbookSectionListing(id, digitalTextbookListing, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.getDigitalTextbookSectionListing']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this frontoffice page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFrontofficePage(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FrontofficePage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFrontofficePage(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.getFrontofficePage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHighSchoolList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HighSchoolList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHighSchoolList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.getHighSchoolList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLastViewedCourseModule(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LastViewedCourseModule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLastViewedCourseModule(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.getLastViewedCourseModule']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} course 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLastViewedLessonInCourse(course: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ViewedLesson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLastViewedLessonInCourse(course, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.getLastViewedLessonInCourse']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} courseModule 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLastViewedLessonInCourseModule(courseModule: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ViewedLesson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLastViewedLessonInCourseModule(courseModule, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.getLastViewedLessonInCourseModule']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Lesson.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLesson(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Lesson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLesson(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.getLesson']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLessonList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedLessonListList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLessonList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.getLessonList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUniversityList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UniversityList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUniversityList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.getUniversityList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUniversityProgramsList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UniversityProgramsList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUniversityProgramsList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.getUniversityProgramsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Viewed Lesson.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getViewedLesson(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ViewedLesson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getViewedLesson(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.getViewedLesson']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} courseModule 
         * @param {number} [course] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {number} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getViewedLessonList(courseModule: number, course?: number, limit?: number, offset?: number, user?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedViewedLessonListList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getViewedLessonList(courseModule, course, limit, offset, user, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.getViewedLessonList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Viewed Problem.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getViewedProblem(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ViewedProblem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getViewedProblem(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.getViewedProblem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [digitalTextbookSectionListing] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {number} [problemSource] 
         * @param {number} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getViewedProblemList(digitalTextbookSectionListing?: number, limit?: number, offset?: number, problemSource?: number, user?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedViewedProblemListList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getViewedProblemList(digitalTextbookSectionListing, limit, offset, problemSource, user, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.getViewedProblemList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user.
         * @param {PatchedSelfUpdateUserRequest} [patchedSelfUpdateUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCurrentUser(id: number, patchedSelfUpdateUserRequest?: PatchedSelfUpdateUserRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelfUpdateUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCurrentUser(id, patchedSelfUpdateUserRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.updateCurrentUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FrontofficeApi - factory interface
 * @export
 */
export const FrontofficeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FrontofficeApiFp(configuration)
    return {
        /**
         * 
         * @param {FrontofficeApiCreateViewedLessonRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createViewedLesson(requestParameters: FrontofficeApiCreateViewedLessonRequest, options?: RawAxiosRequestConfig): AxiosPromise<ViewedLessonCreate> {
            return localVarFp.createViewedLesson(requestParameters.viewedLessonCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiCreateViewedProblemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createViewedProblem(requestParameters: FrontofficeApiCreateViewedProblemRequest, options?: RawAxiosRequestConfig): AxiosPromise<ViewedProblemCreate> {
            return localVarFp.createViewedProblem(requestParameters.viewedProblemCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiFrontofficeApiCourseModuleListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiCourseModuleList(requestParameters: FrontofficeApiFrontofficeApiCourseModuleListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedCourseModuleList> {
            return localVarFp.frontofficeApiCourseModuleList(requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiCourseModulePreviewRetrieve(options?: RawAxiosRequestConfig): AxiosPromise<CourseModule> {
            return localVarFp.frontofficeApiCourseModulePreviewRetrieve(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiFrontofficeApiCurrentUserCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiCurrentUserCreate(requestParameters: FrontofficeApiFrontofficeApiCurrentUserCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<SelfRetrieveUser> {
            return localVarFp.frontofficeApiCurrentUserCreate(requestParameters.selfRetrieveUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiFrontofficeApiCurrentUserDestroyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiCurrentUserDestroy(requestParameters: FrontofficeApiFrontofficeApiCurrentUserDestroyRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.frontofficeApiCurrentUserDestroy(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiFrontofficeApiCurrentUserListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiCurrentUserList(requestParameters: FrontofficeApiFrontofficeApiCurrentUserListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedSelfRetrieveUserList> {
            return localVarFp.frontofficeApiCurrentUserList(requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiFrontofficeApiCurrentUserUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiCurrentUserUpdate(requestParameters: FrontofficeApiFrontofficeApiCurrentUserUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<SelfRetrieveUser> {
            return localVarFp.frontofficeApiCurrentUserUpdate(requestParameters.id, requestParameters.selfRetrieveUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiFrontofficeApiDigitalTextbookSectionListingListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiDigitalTextbookSectionListingList(requestParameters: FrontofficeApiFrontofficeApiDigitalTextbookSectionListingListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedDigitalTextbookSectionListingWithProblemsList> {
            return localVarFp.frontofficeApiDigitalTextbookSectionListingList(requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiFrontofficeApiPageListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiPageList(requestParameters: FrontofficeApiFrontofficeApiPageListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedFrontofficePageList> {
            return localVarFp.frontofficeApiPageList(requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiFrontofficeApiViewedLessonDestroyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiViewedLessonDestroy(requestParameters: FrontofficeApiFrontofficeApiViewedLessonDestroyRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.frontofficeApiViewedLessonDestroy(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiFrontofficeApiViewedLessonPartialUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiViewedLessonPartialUpdate(requestParameters: FrontofficeApiFrontofficeApiViewedLessonPartialUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<ViewedLesson> {
            return localVarFp.frontofficeApiViewedLessonPartialUpdate(requestParameters.id, requestParameters.patchedViewedLessonRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiFrontofficeApiViewedLessonUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiViewedLessonUpdate(requestParameters: FrontofficeApiFrontofficeApiViewedLessonUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<ViewedLesson> {
            return localVarFp.frontofficeApiViewedLessonUpdate(requestParameters.id, requestParameters.viewedLessonRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiFrontofficeApiViewedProblemDestroyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiViewedProblemDestroy(requestParameters: FrontofficeApiFrontofficeApiViewedProblemDestroyRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.frontofficeApiViewedProblemDestroy(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiFrontofficeApiViewedProblemPartialUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiViewedProblemPartialUpdate(requestParameters: FrontofficeApiFrontofficeApiViewedProblemPartialUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<ViewedProblem> {
            return localVarFp.frontofficeApiViewedProblemPartialUpdate(requestParameters.id, requestParameters.patchedViewedProblemRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiFrontofficeApiViewedProblemUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiViewedProblemUpdate(requestParameters: FrontofficeApiFrontofficeApiViewedProblemUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<ViewedProblem> {
            return localVarFp.frontofficeApiViewedProblemUpdate(requestParameters.id, requestParameters.viewedProblemRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiGetCourseModuleRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseModule(requestParameters: FrontofficeApiGetCourseModuleRequest, options?: RawAxiosRequestConfig): AxiosPromise<CourseModule> {
            return localVarFp.getCourseModule(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiGetCurrentUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUser(requestParameters: FrontofficeApiGetCurrentUserRequest, options?: RawAxiosRequestConfig): AxiosPromise<SelfRetrieveUser> {
            return localVarFp.getCurrentUser(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiGetDigitalTextbookSectionListingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDigitalTextbookSectionListing(requestParameters: FrontofficeApiGetDigitalTextbookSectionListingRequest, options?: RawAxiosRequestConfig): AxiosPromise<DigitalTextbookSectionListingWithProblems> {
            return localVarFp.getDigitalTextbookSectionListing(requestParameters.id, requestParameters.digitalTextbookListing, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiGetFrontofficePageRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFrontofficePage(requestParameters: FrontofficeApiGetFrontofficePageRequest, options?: RawAxiosRequestConfig): AxiosPromise<FrontofficePage> {
            return localVarFp.getFrontofficePage(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHighSchoolList(options?: RawAxiosRequestConfig): AxiosPromise<Array<HighSchoolList>> {
            return localVarFp.getHighSchoolList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastViewedCourseModule(options?: RawAxiosRequestConfig): AxiosPromise<LastViewedCourseModule> {
            return localVarFp.getLastViewedCourseModule(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiGetLastViewedLessonInCourseRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastViewedLessonInCourse(requestParameters: FrontofficeApiGetLastViewedLessonInCourseRequest, options?: RawAxiosRequestConfig): AxiosPromise<ViewedLesson> {
            return localVarFp.getLastViewedLessonInCourse(requestParameters.course, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiGetLastViewedLessonInCourseModuleRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastViewedLessonInCourseModule(requestParameters: FrontofficeApiGetLastViewedLessonInCourseModuleRequest, options?: RawAxiosRequestConfig): AxiosPromise<ViewedLesson> {
            return localVarFp.getLastViewedLessonInCourseModule(requestParameters.courseModule, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiGetLessonRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLesson(requestParameters: FrontofficeApiGetLessonRequest, options?: RawAxiosRequestConfig): AxiosPromise<Lesson> {
            return localVarFp.getLesson(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiGetLessonListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLessonList(requestParameters: FrontofficeApiGetLessonListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedLessonListList> {
            return localVarFp.getLessonList(requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUniversityList(options?: RawAxiosRequestConfig): AxiosPromise<Array<UniversityList>> {
            return localVarFp.getUniversityList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUniversityProgramsList(options?: RawAxiosRequestConfig): AxiosPromise<Array<UniversityProgramsList>> {
            return localVarFp.getUniversityProgramsList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiGetViewedLessonRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViewedLesson(requestParameters: FrontofficeApiGetViewedLessonRequest, options?: RawAxiosRequestConfig): AxiosPromise<ViewedLesson> {
            return localVarFp.getViewedLesson(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiGetViewedLessonListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViewedLessonList(requestParameters: FrontofficeApiGetViewedLessonListRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedViewedLessonListList> {
            return localVarFp.getViewedLessonList(requestParameters.courseModule, requestParameters.course, requestParameters.limit, requestParameters.offset, requestParameters.user, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiGetViewedProblemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViewedProblem(requestParameters: FrontofficeApiGetViewedProblemRequest, options?: RawAxiosRequestConfig): AxiosPromise<ViewedProblem> {
            return localVarFp.getViewedProblem(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiGetViewedProblemListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViewedProblemList(requestParameters: FrontofficeApiGetViewedProblemListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedViewedProblemListList> {
            return localVarFp.getViewedProblemList(requestParameters.digitalTextbookSectionListing, requestParameters.limit, requestParameters.offset, requestParameters.problemSource, requestParameters.user, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiUpdateCurrentUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCurrentUser(requestParameters: FrontofficeApiUpdateCurrentUserRequest, options?: RawAxiosRequestConfig): AxiosPromise<SelfUpdateUser> {
            return localVarFp.updateCurrentUser(requestParameters.id, requestParameters.patchedSelfUpdateUserRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createViewedLesson operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiCreateViewedLessonRequest
 */
export interface FrontofficeApiCreateViewedLessonRequest {
    /**
     * 
     * @type {ViewedLessonCreateRequest}
     * @memberof FrontofficeApiCreateViewedLesson
     */
    readonly viewedLessonCreateRequest: ViewedLessonCreateRequest
}

/**
 * Request parameters for createViewedProblem operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiCreateViewedProblemRequest
 */
export interface FrontofficeApiCreateViewedProblemRequest {
    /**
     * 
     * @type {ViewedProblemCreateRequest}
     * @memberof FrontofficeApiCreateViewedProblem
     */
    readonly viewedProblemCreateRequest: ViewedProblemCreateRequest
}

/**
 * Request parameters for frontofficeApiCourseModuleList operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiFrontofficeApiCourseModuleListRequest
 */
export interface FrontofficeApiFrontofficeApiCourseModuleListRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof FrontofficeApiFrontofficeApiCourseModuleList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof FrontofficeApiFrontofficeApiCourseModuleList
     */
    readonly offset?: number
}

/**
 * Request parameters for frontofficeApiCurrentUserCreate operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiFrontofficeApiCurrentUserCreateRequest
 */
export interface FrontofficeApiFrontofficeApiCurrentUserCreateRequest {
    /**
     * 
     * @type {SelfRetrieveUserRequest}
     * @memberof FrontofficeApiFrontofficeApiCurrentUserCreate
     */
    readonly selfRetrieveUserRequest: SelfRetrieveUserRequest
}

/**
 * Request parameters for frontofficeApiCurrentUserDestroy operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiFrontofficeApiCurrentUserDestroyRequest
 */
export interface FrontofficeApiFrontofficeApiCurrentUserDestroyRequest {
    /**
     * A unique integer value identifying this user.
     * @type {number}
     * @memberof FrontofficeApiFrontofficeApiCurrentUserDestroy
     */
    readonly id: number
}

/**
 * Request parameters for frontofficeApiCurrentUserList operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiFrontofficeApiCurrentUserListRequest
 */
export interface FrontofficeApiFrontofficeApiCurrentUserListRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof FrontofficeApiFrontofficeApiCurrentUserList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof FrontofficeApiFrontofficeApiCurrentUserList
     */
    readonly offset?: number
}

/**
 * Request parameters for frontofficeApiCurrentUserUpdate operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiFrontofficeApiCurrentUserUpdateRequest
 */
export interface FrontofficeApiFrontofficeApiCurrentUserUpdateRequest {
    /**
     * A unique integer value identifying this user.
     * @type {number}
     * @memberof FrontofficeApiFrontofficeApiCurrentUserUpdate
     */
    readonly id: number

    /**
     * 
     * @type {SelfRetrieveUserRequest}
     * @memberof FrontofficeApiFrontofficeApiCurrentUserUpdate
     */
    readonly selfRetrieveUserRequest: SelfRetrieveUserRequest
}

/**
 * Request parameters for frontofficeApiDigitalTextbookSectionListingList operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiFrontofficeApiDigitalTextbookSectionListingListRequest
 */
export interface FrontofficeApiFrontofficeApiDigitalTextbookSectionListingListRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof FrontofficeApiFrontofficeApiDigitalTextbookSectionListingList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof FrontofficeApiFrontofficeApiDigitalTextbookSectionListingList
     */
    readonly offset?: number
}

/**
 * Request parameters for frontofficeApiPageList operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiFrontofficeApiPageListRequest
 */
export interface FrontofficeApiFrontofficeApiPageListRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof FrontofficeApiFrontofficeApiPageList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof FrontofficeApiFrontofficeApiPageList
     */
    readonly offset?: number
}

/**
 * Request parameters for frontofficeApiViewedLessonDestroy operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiFrontofficeApiViewedLessonDestroyRequest
 */
export interface FrontofficeApiFrontofficeApiViewedLessonDestroyRequest {
    /**
     * A unique integer value identifying this Viewed Lesson.
     * @type {number}
     * @memberof FrontofficeApiFrontofficeApiViewedLessonDestroy
     */
    readonly id: number
}

/**
 * Request parameters for frontofficeApiViewedLessonPartialUpdate operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiFrontofficeApiViewedLessonPartialUpdateRequest
 */
export interface FrontofficeApiFrontofficeApiViewedLessonPartialUpdateRequest {
    /**
     * A unique integer value identifying this Viewed Lesson.
     * @type {number}
     * @memberof FrontofficeApiFrontofficeApiViewedLessonPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {PatchedViewedLessonRequest}
     * @memberof FrontofficeApiFrontofficeApiViewedLessonPartialUpdate
     */
    readonly patchedViewedLessonRequest?: PatchedViewedLessonRequest
}

/**
 * Request parameters for frontofficeApiViewedLessonUpdate operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiFrontofficeApiViewedLessonUpdateRequest
 */
export interface FrontofficeApiFrontofficeApiViewedLessonUpdateRequest {
    /**
     * A unique integer value identifying this Viewed Lesson.
     * @type {number}
     * @memberof FrontofficeApiFrontofficeApiViewedLessonUpdate
     */
    readonly id: number

    /**
     * 
     * @type {ViewedLessonRequest}
     * @memberof FrontofficeApiFrontofficeApiViewedLessonUpdate
     */
    readonly viewedLessonRequest: ViewedLessonRequest
}

/**
 * Request parameters for frontofficeApiViewedProblemDestroy operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiFrontofficeApiViewedProblemDestroyRequest
 */
export interface FrontofficeApiFrontofficeApiViewedProblemDestroyRequest {
    /**
     * A unique integer value identifying this Viewed Problem.
     * @type {number}
     * @memberof FrontofficeApiFrontofficeApiViewedProblemDestroy
     */
    readonly id: number
}

/**
 * Request parameters for frontofficeApiViewedProblemPartialUpdate operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiFrontofficeApiViewedProblemPartialUpdateRequest
 */
export interface FrontofficeApiFrontofficeApiViewedProblemPartialUpdateRequest {
    /**
     * A unique integer value identifying this Viewed Problem.
     * @type {number}
     * @memberof FrontofficeApiFrontofficeApiViewedProblemPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {PatchedViewedProblemRequest}
     * @memberof FrontofficeApiFrontofficeApiViewedProblemPartialUpdate
     */
    readonly patchedViewedProblemRequest?: PatchedViewedProblemRequest
}

/**
 * Request parameters for frontofficeApiViewedProblemUpdate operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiFrontofficeApiViewedProblemUpdateRequest
 */
export interface FrontofficeApiFrontofficeApiViewedProblemUpdateRequest {
    /**
     * A unique integer value identifying this Viewed Problem.
     * @type {number}
     * @memberof FrontofficeApiFrontofficeApiViewedProblemUpdate
     */
    readonly id: number

    /**
     * 
     * @type {ViewedProblemRequest}
     * @memberof FrontofficeApiFrontofficeApiViewedProblemUpdate
     */
    readonly viewedProblemRequest: ViewedProblemRequest
}

/**
 * Request parameters for getCourseModule operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiGetCourseModuleRequest
 */
export interface FrontofficeApiGetCourseModuleRequest {
    /**
     * A unique integer value identifying this course module.
     * @type {number}
     * @memberof FrontofficeApiGetCourseModule
     */
    readonly id: number
}

/**
 * Request parameters for getCurrentUser operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiGetCurrentUserRequest
 */
export interface FrontofficeApiGetCurrentUserRequest {
    /**
     * A unique integer value identifying this user.
     * @type {number}
     * @memberof FrontofficeApiGetCurrentUser
     */
    readonly id: number
}

/**
 * Request parameters for getDigitalTextbookSectionListing operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiGetDigitalTextbookSectionListingRequest
 */
export interface FrontofficeApiGetDigitalTextbookSectionListingRequest {
    /**
     * A unique integer value identifying this digital textbook section listing.
     * @type {number}
     * @memberof FrontofficeApiGetDigitalTextbookSectionListing
     */
    readonly id: number

    /**
     * Digital textbook listing id
     * @type {number}
     * @memberof FrontofficeApiGetDigitalTextbookSectionListing
     */
    readonly digitalTextbookListing?: number
}

/**
 * Request parameters for getFrontofficePage operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiGetFrontofficePageRequest
 */
export interface FrontofficeApiGetFrontofficePageRequest {
    /**
     * A unique integer value identifying this frontoffice page.
     * @type {number}
     * @memberof FrontofficeApiGetFrontofficePage
     */
    readonly id: number
}

/**
 * Request parameters for getLastViewedLessonInCourse operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiGetLastViewedLessonInCourseRequest
 */
export interface FrontofficeApiGetLastViewedLessonInCourseRequest {
    /**
     * 
     * @type {number}
     * @memberof FrontofficeApiGetLastViewedLessonInCourse
     */
    readonly course: number
}

/**
 * Request parameters for getLastViewedLessonInCourseModule operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiGetLastViewedLessonInCourseModuleRequest
 */
export interface FrontofficeApiGetLastViewedLessonInCourseModuleRequest {
    /**
     * 
     * @type {number}
     * @memberof FrontofficeApiGetLastViewedLessonInCourseModule
     */
    readonly courseModule: number
}

/**
 * Request parameters for getLesson operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiGetLessonRequest
 */
export interface FrontofficeApiGetLessonRequest {
    /**
     * A unique integer value identifying this Lesson.
     * @type {number}
     * @memberof FrontofficeApiGetLesson
     */
    readonly id: number
}

/**
 * Request parameters for getLessonList operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiGetLessonListRequest
 */
export interface FrontofficeApiGetLessonListRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof FrontofficeApiGetLessonList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof FrontofficeApiGetLessonList
     */
    readonly offset?: number
}

/**
 * Request parameters for getViewedLesson operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiGetViewedLessonRequest
 */
export interface FrontofficeApiGetViewedLessonRequest {
    /**
     * A unique integer value identifying this Viewed Lesson.
     * @type {number}
     * @memberof FrontofficeApiGetViewedLesson
     */
    readonly id: number
}

/**
 * Request parameters for getViewedLessonList operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiGetViewedLessonListRequest
 */
export interface FrontofficeApiGetViewedLessonListRequest {
    /**
     * 
     * @type {number}
     * @memberof FrontofficeApiGetViewedLessonList
     */
    readonly courseModule: number

    /**
     * 
     * @type {number}
     * @memberof FrontofficeApiGetViewedLessonList
     */
    readonly course?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof FrontofficeApiGetViewedLessonList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof FrontofficeApiGetViewedLessonList
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof FrontofficeApiGetViewedLessonList
     */
    readonly user?: number
}

/**
 * Request parameters for getViewedProblem operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiGetViewedProblemRequest
 */
export interface FrontofficeApiGetViewedProblemRequest {
    /**
     * A unique integer value identifying this Viewed Problem.
     * @type {number}
     * @memberof FrontofficeApiGetViewedProblem
     */
    readonly id: number
}

/**
 * Request parameters for getViewedProblemList operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiGetViewedProblemListRequest
 */
export interface FrontofficeApiGetViewedProblemListRequest {
    /**
     * 
     * @type {number}
     * @memberof FrontofficeApiGetViewedProblemList
     */
    readonly digitalTextbookSectionListing?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof FrontofficeApiGetViewedProblemList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof FrontofficeApiGetViewedProblemList
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof FrontofficeApiGetViewedProblemList
     */
    readonly problemSource?: number

    /**
     * 
     * @type {number}
     * @memberof FrontofficeApiGetViewedProblemList
     */
    readonly user?: number
}

/**
 * Request parameters for updateCurrentUser operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiUpdateCurrentUserRequest
 */
export interface FrontofficeApiUpdateCurrentUserRequest {
    /**
     * A unique integer value identifying this user.
     * @type {number}
     * @memberof FrontofficeApiUpdateCurrentUser
     */
    readonly id: number

    /**
     * 
     * @type {PatchedSelfUpdateUserRequest}
     * @memberof FrontofficeApiUpdateCurrentUser
     */
    readonly patchedSelfUpdateUserRequest?: PatchedSelfUpdateUserRequest
}

/**
 * FrontofficeApi - object-oriented interface
 * @export
 * @class FrontofficeApi
 * @extends {BaseAPI}
 */
export class FrontofficeApi extends BaseAPI {
    /**
     * 
     * @param {FrontofficeApiCreateViewedLessonRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public createViewedLesson(requestParameters: FrontofficeApiCreateViewedLessonRequest, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).createViewedLesson(requestParameters.viewedLessonCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiCreateViewedProblemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public createViewedProblem(requestParameters: FrontofficeApiCreateViewedProblemRequest, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).createViewedProblem(requestParameters.viewedProblemCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiFrontofficeApiCourseModuleListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public frontofficeApiCourseModuleList(requestParameters: FrontofficeApiFrontofficeApiCourseModuleListRequest = {}, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).frontofficeApiCourseModuleList(requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public frontofficeApiCourseModulePreviewRetrieve(options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).frontofficeApiCourseModulePreviewRetrieve(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiFrontofficeApiCurrentUserCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public frontofficeApiCurrentUserCreate(requestParameters: FrontofficeApiFrontofficeApiCurrentUserCreateRequest, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).frontofficeApiCurrentUserCreate(requestParameters.selfRetrieveUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiFrontofficeApiCurrentUserDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public frontofficeApiCurrentUserDestroy(requestParameters: FrontofficeApiFrontofficeApiCurrentUserDestroyRequest, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).frontofficeApiCurrentUserDestroy(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiFrontofficeApiCurrentUserListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public frontofficeApiCurrentUserList(requestParameters: FrontofficeApiFrontofficeApiCurrentUserListRequest = {}, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).frontofficeApiCurrentUserList(requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiFrontofficeApiCurrentUserUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public frontofficeApiCurrentUserUpdate(requestParameters: FrontofficeApiFrontofficeApiCurrentUserUpdateRequest, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).frontofficeApiCurrentUserUpdate(requestParameters.id, requestParameters.selfRetrieveUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiFrontofficeApiDigitalTextbookSectionListingListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public frontofficeApiDigitalTextbookSectionListingList(requestParameters: FrontofficeApiFrontofficeApiDigitalTextbookSectionListingListRequest = {}, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).frontofficeApiDigitalTextbookSectionListingList(requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiFrontofficeApiPageListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public frontofficeApiPageList(requestParameters: FrontofficeApiFrontofficeApiPageListRequest = {}, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).frontofficeApiPageList(requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiFrontofficeApiViewedLessonDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public frontofficeApiViewedLessonDestroy(requestParameters: FrontofficeApiFrontofficeApiViewedLessonDestroyRequest, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).frontofficeApiViewedLessonDestroy(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiFrontofficeApiViewedLessonPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public frontofficeApiViewedLessonPartialUpdate(requestParameters: FrontofficeApiFrontofficeApiViewedLessonPartialUpdateRequest, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).frontofficeApiViewedLessonPartialUpdate(requestParameters.id, requestParameters.patchedViewedLessonRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiFrontofficeApiViewedLessonUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public frontofficeApiViewedLessonUpdate(requestParameters: FrontofficeApiFrontofficeApiViewedLessonUpdateRequest, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).frontofficeApiViewedLessonUpdate(requestParameters.id, requestParameters.viewedLessonRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiFrontofficeApiViewedProblemDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public frontofficeApiViewedProblemDestroy(requestParameters: FrontofficeApiFrontofficeApiViewedProblemDestroyRequest, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).frontofficeApiViewedProblemDestroy(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiFrontofficeApiViewedProblemPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public frontofficeApiViewedProblemPartialUpdate(requestParameters: FrontofficeApiFrontofficeApiViewedProblemPartialUpdateRequest, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).frontofficeApiViewedProblemPartialUpdate(requestParameters.id, requestParameters.patchedViewedProblemRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiFrontofficeApiViewedProblemUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public frontofficeApiViewedProblemUpdate(requestParameters: FrontofficeApiFrontofficeApiViewedProblemUpdateRequest, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).frontofficeApiViewedProblemUpdate(requestParameters.id, requestParameters.viewedProblemRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiGetCourseModuleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public getCourseModule(requestParameters: FrontofficeApiGetCourseModuleRequest, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).getCourseModule(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiGetCurrentUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public getCurrentUser(requestParameters: FrontofficeApiGetCurrentUserRequest, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).getCurrentUser(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiGetDigitalTextbookSectionListingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public getDigitalTextbookSectionListing(requestParameters: FrontofficeApiGetDigitalTextbookSectionListingRequest, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).getDigitalTextbookSectionListing(requestParameters.id, requestParameters.digitalTextbookListing, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiGetFrontofficePageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public getFrontofficePage(requestParameters: FrontofficeApiGetFrontofficePageRequest, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).getFrontofficePage(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public getHighSchoolList(options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).getHighSchoolList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public getLastViewedCourseModule(options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).getLastViewedCourseModule(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiGetLastViewedLessonInCourseRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public getLastViewedLessonInCourse(requestParameters: FrontofficeApiGetLastViewedLessonInCourseRequest, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).getLastViewedLessonInCourse(requestParameters.course, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiGetLastViewedLessonInCourseModuleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public getLastViewedLessonInCourseModule(requestParameters: FrontofficeApiGetLastViewedLessonInCourseModuleRequest, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).getLastViewedLessonInCourseModule(requestParameters.courseModule, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiGetLessonRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public getLesson(requestParameters: FrontofficeApiGetLessonRequest, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).getLesson(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiGetLessonListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public getLessonList(requestParameters: FrontofficeApiGetLessonListRequest = {}, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).getLessonList(requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public getUniversityList(options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).getUniversityList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public getUniversityProgramsList(options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).getUniversityProgramsList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiGetViewedLessonRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public getViewedLesson(requestParameters: FrontofficeApiGetViewedLessonRequest, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).getViewedLesson(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiGetViewedLessonListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public getViewedLessonList(requestParameters: FrontofficeApiGetViewedLessonListRequest, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).getViewedLessonList(requestParameters.courseModule, requestParameters.course, requestParameters.limit, requestParameters.offset, requestParameters.user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiGetViewedProblemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public getViewedProblem(requestParameters: FrontofficeApiGetViewedProblemRequest, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).getViewedProblem(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiGetViewedProblemListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public getViewedProblemList(requestParameters: FrontofficeApiGetViewedProblemListRequest = {}, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).getViewedProblemList(requestParameters.digitalTextbookSectionListing, requestParameters.limit, requestParameters.offset, requestParameters.problemSource, requestParameters.user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiUpdateCurrentUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public updateCurrentUser(requestParameters: FrontofficeApiUpdateCurrentUserRequest, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).updateCurrentUser(requestParameters.id, requestParameters.patchedSelfUpdateUserRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

