/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `page_link` - page_link * `external_link` - external_link
 * @export
 * @enum {string}
 */

export const Type3d9Enum = {
    PageLink: 'page_link',
    ExternalLink: 'external_link'
} as const;

export type Type3d9Enum = typeof Type3d9Enum[keyof typeof Type3d9Enum];



