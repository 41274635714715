/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `universities` - Universities * `high_schools` - High schools * `university_programs` - University programs
 * @export
 * @enum {string}
 */

export const ChoicesSourceEnum = {
    Universities: 'universities',
    HighSchools: 'high_schools',
    UniversityPrograms: 'university_programs'
} as const;

export type ChoicesSourceEnum = typeof ChoicesSourceEnum[keyof typeof ChoicesSourceEnum];



