/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `active` - Active * `hidden` - Hidden * `coming_soon` - Coming Soon * `new` - New * `disabled` - Disabled * `work_in_progress` - Work in Progress
 * @export
 * @enum {string}
 */

export const ServiceCardStatusEnum = {
    Active: 'active',
    Hidden: 'hidden',
    ComingSoon: 'coming_soon',
    New: 'new',
    Disabled: 'disabled',
    WorkInProgress: 'work_in_progress'
} as const;

export type ServiceCardStatusEnum = typeof ServiceCardStatusEnum[keyof typeof ServiceCardStatusEnum];



