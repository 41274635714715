/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `exam` - Exam * `practice` - Practice
 * @export
 * @enum {string}
 */

export const Type99eEnum = {
    Exam: 'exam',
    Practice: 'practice'
} as const;

export type Type99eEnum = typeof Type99eEnum[keyof typeof Type99eEnum];



