/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `link_with_icon_list` - link_with_icon_list * `course_listing` - course_listing * `course_listing_list` - course_listing_list * `user_data_form_listing` - user_data_form_listing
 * @export
 * @enum {string}
 */

export const FrontofficePageBlockSharedTypeEnum = {
    LinkWithIconList: 'link_with_icon_list',
    CourseListing: 'course_listing',
    CourseListingList: 'course_listing_list',
    UserDataFormListing: 'user_data_form_listing'
} as const;

export type FrontofficePageBlockSharedTypeEnum = typeof FrontofficePageBlockSharedTypeEnum[keyof typeof FrontofficePageBlockSharedTypeEnum];



