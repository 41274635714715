/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `red` - Red * `yellow` - Yellow * `blue` - Blue * `light` - Light * `dark` - Dark
 * @export
 * @enum {string}
 */

export const BtnColorEnum = {
    Red: 'red',
    Yellow: 'yellow',
    Blue: 'blue',
    Light: 'light',
    Dark: 'dark'
} as const;

export type BtnColorEnum = typeof BtnColorEnum[keyof typeof BtnColorEnum];



