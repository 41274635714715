/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `free_preview` - Free preview * `available` - Available * `disabled` - Disabled * `hidden` - Hidden * `available_soon` - Available soon
 * @export
 * @enum {string}
 */

export const VisibilityStatusE2dEnum = {
    FreePreview: 'free_preview',
    Available: 'available',
    Disabled: 'disabled',
    Hidden: 'hidden',
    AvailableSoon: 'available_soon'
} as const;

export type VisibilityStatusE2dEnum = typeof VisibilityStatusE2dEnum[keyof typeof VisibilityStatusE2dEnum];



