/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `PENDING` - Pending * `REVIEW_STARTED` - Review started * `REVIEW_SKIPPED` - Review skipped * `REVIEW_DONE` - Review done
 * @export
 * @enum {string}
 */

export const ReviewStatusCdfEnum = {
    Pending: 'PENDING',
    ReviewStarted: 'REVIEW_STARTED',
    ReviewSkipped: 'REVIEW_SKIPPED',
    ReviewDone: 'REVIEW_DONE'
} as const;

export type ReviewStatusCdfEnum = typeof ReviewStatusCdfEnum[keyof typeof ReviewStatusCdfEnum];



