/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `PENDING` - Pending * `IN_REVIEW` - In review * `PARTIALLY_REVIEWED` - Partially reviewed * `REVIEWED` - Reviewed * `SENT_TO_USER` - Sent to user
 * @export
 * @enum {string}
 */

export const AssignmentAttemptReviewStatusEnum = {
    Pending: 'PENDING',
    InReview: 'IN_REVIEW',
    PartiallyReviewed: 'PARTIALLY_REVIEWED',
    Reviewed: 'REVIEWED',
    SentToUser: 'SENT_TO_USER'
} as const;

export type AssignmentAttemptReviewStatusEnum = typeof AssignmentAttemptReviewStatusEnum[keyof typeof AssignmentAttemptReviewStatusEnum];



