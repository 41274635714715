/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `richtext` - richtext * `countdown` - countdown * `label` - label
 * @export
 * @enum {string}
 */

export const AnnouncementBarBlockSharedTypeEnum = {
    Richtext: 'richtext',
    Countdown: 'countdown',
    Label: 'label'
} as const;

export type AnnouncementBarBlockSharedTypeEnum = typeof AnnouncementBarBlockSharedTypeEnum[keyof typeof AnnouncementBarBlockSharedTypeEnum];



