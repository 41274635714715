/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `free_for_registered` - Free for registered user * `free_preview` - Free preview * `available` - Available * `disabled` - Disabled * `hidden` - Hidden
 * @export
 * @enum {string}
 */

export const VisibilityStatusD1bEnum = {
    FreeForRegistered: 'free_for_registered',
    FreePreview: 'free_preview',
    Available: 'available',
    Disabled: 'disabled',
    Hidden: 'hidden'
} as const;

export type VisibilityStatusD1bEnum = typeof VisibilityStatusD1bEnum[keyof typeof VisibilityStatusD1bEnum];



