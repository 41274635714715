/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `blue` - Blue background * `yellow` - Yellow background * `transparent` - Transparent background * `white` - White background
 * @export
 * @enum {string}
 */

export const BackgroundColorEnum = {
    Blue: 'blue',
    Yellow: 'yellow',
    Transparent: 'transparent',
    White: 'white'
} as const;

export type BackgroundColorEnum = typeof BackgroundColorEnum[keyof typeof BackgroundColorEnum];



