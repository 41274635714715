/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `IconTrophy` - Trophy * `IconSchool` - School * `IconFriends` - Friends * `IconBrandYoutube` - Youtube * `IconBulb` - Bulb * `IconForbid2` - Forbid2
 * @export
 * @enum {string}
 */

export const FeaturesGridIconEnum = {
    IconTrophy: 'IconTrophy',
    IconSchool: 'IconSchool',
    IconFriends: 'IconFriends',
    IconBrandYoutube: 'IconBrandYoutube',
    IconBulb: 'IconBulb',
    IconForbid2: 'IconForbid2'
} as const;

export type FeaturesGridIconEnum = typeof FeaturesGridIconEnum[keyof typeof FeaturesGridIconEnum];



