/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `PeaceHand` - Peace hand * `MuscleHand` - Muscle hand * `ClapHands` - Clap hands * `HandWave` - Hand wave * `HandThumbsUp` - Hand thumbs up
 * @export
 * @enum {string}
 */

export const FeaturesBlockIconEnum = {
    PeaceHand: 'PeaceHand',
    MuscleHand: 'MuscleHand',
    ClapHands: 'ClapHands',
    HandWave: 'HandWave',
    HandThumbsUp: 'HandThumbsUp'
} as const;

export type FeaturesBlockIconEnum = typeof FeaturesBlockIconEnum[keyof typeof FeaturesBlockIconEnum];



