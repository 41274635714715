/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `primary` - Primary * `outline` - Outline * `ghost` - Ghost * `link` - Link
 * @export
 * @enum {string}
 */

export const BtnVariantEnum = {
    Primary: 'primary',
    Outline: 'outline',
    Ghost: 'ghost',
    Link: 'link'
} as const;

export type BtnVariantEnum = typeof BtnVariantEnum[keyof typeof BtnVariantEnum];



