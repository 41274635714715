/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `IconMathSymbols` - Math symbols * `IconMath` - Math * `IconGlobe` - Globe * `IconAtom` - Atom * `IconBook` - Book * `IconAlphabetLatin` - Alphabet latin * `IconFlask` - Flask
 * @export
 * @enum {string}
 */

export const SubjectIconEnum = {
    IconMathSymbols: 'IconMathSymbols',
    IconMath: 'IconMath',
    IconGlobe: 'IconGlobe',
    IconAtom: 'IconAtom',
    IconBook: 'IconBook',
    IconAlphabetLatin: 'IconAlphabetLatin',
    IconFlask: 'IconFlask'
} as const;

export type SubjectIconEnum = typeof SubjectIconEnum[keyof typeof SubjectIconEnum];



