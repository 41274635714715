/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `video` - Video * `problem` - Problem * `assignment` - Assignment * `quiz` - Quiz * `textbook_section` - Textbook section * `table_with_tabs` - Table with tabs * `timeline` - Timeline
 * @export
 * @enum {string}
 */

export const LessonTypeEnum = {
    Video: 'video',
    Problem: 'problem',
    Assignment: 'assignment',
    Quiz: 'quiz',
    TextbookSection: 'textbook_section',
    TableWithTabs: 'table_with_tabs',
    Timeline: 'timeline'
} as const;

export type LessonTypeEnum = typeof LessonTypeEnum[keyof typeof LessonTypeEnum];



