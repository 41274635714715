/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `75%` - Image 75% * `66%` - Image 66% * `50%` - Image 50% * `33%` - Image 33% * `25%` - Image 25%
 * @export
 * @enum {string}
 */

export const ImageWidthEnum = {
    _75: '75%',
    _66: '66%',
    _50: '50%',
    _33: '33%',
    _25: '25%'
} as const;

export type ImageWidthEnum = typeof ImageWidthEnum[keyof typeof ImageWidthEnum];



