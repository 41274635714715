/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `bullet` - bullet * `richtext` - richtext * `button` - button * `multiple_size_image` - multiple_size_image * `table` - table
 * @export
 * @enum {string}
 */

export const ModalBlockSharedTypeEnum = {
    Bullet: 'bullet',
    Richtext: 'richtext',
    Button: 'button',
    MultipleSizeImage: 'multiple_size_image',
    Table: 'table'
} as const;

export type ModalBlockSharedTypeEnum = typeof ModalBlockSharedTypeEnum[keyof typeof ModalBlockSharedTypeEnum];



