/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `manual` - Manual * `auto` - Auto * `self_review` - Self review * `ai_review` - AI review
 * @export
 * @enum {string}
 */

export const ReviewTypeEnum = {
    Manual: 'manual',
    Auto: 'auto',
    SelfReview: 'self_review',
    AiReview: 'ai_review'
} as const;

export type ReviewTypeEnum = typeof ReviewTypeEnum[keyof typeof ReviewTypeEnum];



