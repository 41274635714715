/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `ljeto` - Ljetni rok * `jesen` - Jesenski rok * `zima` - Zimski rok * `ogledna` - Ogledni ispit * `probna` - Probni ispit
 * @export
 * @enum {string}
 */

export const TermEnum = {
    Ljeto: 'ljeto',
    Jesen: 'jesen',
    Zima: 'zima',
    Ogledna: 'ogledna',
    Probna: 'probna'
} as const;

export type TermEnum = typeof TermEnum[keyof typeof TermEnum];



