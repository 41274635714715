/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `table` - table * `two_columns` - two_columns * `image_single` - image_single * `raw_html` - raw_html * `equation` - equation * `richtext` - richtext
 * @export
 * @enum {string}
 */

export const DigitalTextbookReusableContentBlockSharedTypeEnum = {
    Table: 'table',
    TwoColumns: 'two_columns',
    ImageSingle: 'image_single',
    RawHtml: 'raw_html',
    Equation: 'equation',
    Richtext: 'richtext'
} as const;

export type DigitalTextbookReusableContentBlockSharedTypeEnum = typeof DigitalTextbookReusableContentBlockSharedTypeEnum[keyof typeof DigitalTextbookReusableContentBlockSharedTypeEnum];



