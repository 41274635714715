/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `IconCheck` - Check * `IconSchool` - School * `IconBallpen` - Ballpen * `IconCalendarWeek` - Calendar week * `IconBooks` - Books * `IconTallymarks` - Tallymarks * `IconDeviceTv` - Device tv * `IconReload` - Reload * `IconX` - X * `IconArrowRight` - Arrow right * `IconPlayerPlay` - Player play
 * @export
 * @enum {string}
 */

export const BulletIconEnum = {
    IconCheck: 'IconCheck',
    IconSchool: 'IconSchool',
    IconBallpen: 'IconBallpen',
    IconCalendarWeek: 'IconCalendarWeek',
    IconBooks: 'IconBooks',
    IconTallymarks: 'IconTallymarks',
    IconDeviceTv: 'IconDeviceTv',
    IconReload: 'IconReload',
    IconX: 'IconX',
    IconArrowRight: 'IconArrowRight',
    IconPlayerPlay: 'IconPlayerPlay'
} as const;

export type BulletIconEnum = typeof BulletIconEnum[keyof typeof BulletIconEnum];



