/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `IconBrandLinkedin` - Linkedin * `IconBrandFacebook` - Facebook * `IconBrandDribbble` - Dribbble * `IconBrandInstagram` - Instagram * `IconBrandYoutube` - Youtube * `IconBrandTiktok` - Tiktok
 * @export
 * @enum {string}
 */

export const SocialMediaIconEnum = {
    IconBrandLinkedin: 'IconBrandLinkedin',
    IconBrandFacebook: 'IconBrandFacebook',
    IconBrandDribbble: 'IconBrandDribbble',
    IconBrandInstagram: 'IconBrandInstagram',
    IconBrandYoutube: 'IconBrandYoutube',
    IconBrandTiktok: 'IconBrandTiktok'
} as const;

export type SocialMediaIconEnum = typeof SocialMediaIconEnum[keyof typeof SocialMediaIconEnum];



