/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `matura_exam` - Matura exam * `textbook` - Textbook
 * @export
 * @enum {string}
 */

export const Type7d4Enum = {
    MaturaExam: 'matura_exam',
    Textbook: 'textbook'
} as const;

export type Type7d4Enum = typeof Type7d4Enum[keyof typeof Type7d4Enum];



