/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `choice` - Choice * `single_line_text` - Single line text * `multi_line_text` - Multi line text * `math_expression` - Math expression
 * @export
 * @enum {string}
 */

export const QuestionAnswerFieldTypeEnum = {
    Choice: 'choice',
    SingleLineText: 'single_line_text',
    MultiLineText: 'multi_line_text',
    MathExpression: 'math_expression'
} as const;

export type QuestionAnswerFieldTypeEnum = typeof QuestionAnswerFieldTypeEnum[keyof typeof QuestionAnswerFieldTypeEnum];



