/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `easy` - Easy * `medium` - Medium * `hard` - Hard
 * @export
 * @enum {string}
 */

export const DifficultyEnum = {
    Easy: 'easy',
    Medium: 'medium',
    Hard: 'hard'
} as const;

export type DifficultyEnum = typeof DifficultyEnum[keyof typeof DifficultyEnum];



