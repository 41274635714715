/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `singleline` - Single line text * `multiline` - Multi-line text * `email` - Email * `number` - Number * `url` - URL * `checkbox` - Checkbox * `checkboxes` - Checkboxes * `dropdown` - Drop down * `multiselect` - Multiple select * `radio` - Radio buttons * `date` - Date * `datetime` - Date/time * `hidden` - Hidden field
 * @export
 * @enum {string}
 */

export const UserDataFormFieldFieldTypeEnum = {
    Singleline: 'singleline',
    Multiline: 'multiline',
    Email: 'email',
    Number: 'number',
    Url: 'url',
    Checkbox: 'checkbox',
    Checkboxes: 'checkboxes',
    Dropdown: 'dropdown',
    Multiselect: 'multiselect',
    Radio: 'radio',
    Date: 'date',
    Datetime: 'datetime',
    Hidden: 'hidden'
} as const;

export type UserDataFormFieldFieldTypeEnum = typeof UserDataFormFieldFieldTypeEnum[keyof typeof UserDataFormFieldFieldTypeEnum];



