/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `IconChevronRight` - Chevron right * `IconArrowRight` - Arrow right * `IconSend` - Send * `` - ------
 * @export
 * @enum {string}
 */

export const BtnIconEnum = {
    IconChevronRight: 'IconChevronRight',
    IconArrowRight: 'IconArrowRight',
    IconSend: 'IconSend'
} as const;

export type BtnIconEnum = typeof BtnIconEnum[keyof typeof BtnIconEnum];



