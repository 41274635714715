/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `MaturaDownloaderPage` - MaturaDownloaderPage * `LandingPage` - LandingPage * `Problem` - Problem * `Product` - Product * `DigitalTextbook` - DigitalTextbook * `LegalPage` - LegalPage * `DigitalTextbookSection` - DigitalTextbookSection * `PasswordProtectedPage` - PasswordProtectedPage
 * @export
 * @enum {string}
 */

export const ComponentNameEnum = {
    MaturaDownloaderPage: 'MaturaDownloaderPage',
    LandingPage: 'LandingPage',
    Problem: 'Problem',
    Product: 'Product',
    DigitalTextbook: 'DigitalTextbook',
    LegalPage: 'LegalPage',
    DigitalTextbookSection: 'DigitalTextbookSection',
    PasswordProtectedPage: 'PasswordProtectedPage'
} as const;

export type ComponentNameEnum = typeof ComponentNameEnum[keyof typeof ComponentNameEnum];



