/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `vocational` - Strukovni program * `gymnasium` - Gimnazijski program * `mathematical` - Matematički program * `linguistic` - Jezični program * `scientific` - Prirodoslovni program
 * @export
 * @enum {string}
 */

export const HighSchoolProgramTypeEnum = {
    Vocational: 'vocational',
    Gymnasium: 'gymnasium',
    Mathematical: 'mathematical',
    Linguistic: 'linguistic',
    Scientific: 'scientific'
} as const;

export type HighSchoolProgramTypeEnum = typeof HighSchoolProgramTypeEnum[keyof typeof HighSchoolProgramTypeEnum];



