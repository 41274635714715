/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `first_year_high_school` - First year high school * `second_year_high_school` - Second year high school * `third_year_high_school` - Third year high school * `fourth_year_high_school` - Fourth year high school * `fifth_year_high_school` - Fifth year high school
 * @export
 * @enum {string}
 */

export const HighSchoolCurrentYearEnum = {
    FirstYearHighSchool: 'first_year_high_school',
    SecondYearHighSchool: 'second_year_high_school',
    ThirdYearHighSchool: 'third_year_high_school',
    FourthYearHighSchool: 'fourth_year_high_school',
    FifthYearHighSchool: 'fifth_year_high_school'
} as const;

export type HighSchoolCurrentYearEnum = typeof HighSchoolCurrentYearEnum[keyof typeof HighSchoolCurrentYearEnum];



