/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `approved` - Approved * `unapproved` - Unapproved * `forcefully_approved` - Forcefully approved * `unprocessed` - Unprocessed * `needs_fixing` - Needs fixing
 * @export
 * @enum {string}
 */

export const ApprovalStatusEnum = {
    Approved: 'approved',
    Unapproved: 'unapproved',
    ForcefullyApproved: 'forcefully_approved',
    Unprocessed: 'unprocessed',
    NeedsFixing: 'needs_fixing'
} as const;

export type ApprovalStatusEnum = typeof ApprovalStatusEnum[keyof typeof ApprovalStatusEnum];



