/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `card` - Card * `full_width` - Full width
 * @export
 * @enum {string}
 */

export const SectionTypeEnum = {
    Card: 'card',
    FullWidth: 'full_width'
} as const;

export type SectionTypeEnum = typeof SectionTypeEnum[keyof typeof SectionTypeEnum];



